<template>
    <section class="ExpanderCardCarousel">
        <div class="ExpanderCardCarousel__head">
            <h2
                v-if="slice.primary.section_title"
                class="ExpanderCardCarousel__head-title"
            >
                {{ slice.primary.section_title }}
            </h2>
            <PrismicRichText
                v-if="slice.primary.section_description.length"
                class="ExpanderCardCarousel__head-desc"
                :field="slice.primary.section_description"
            />
        </div>
        <div class="ExpanderCardCarousel__carousel-wrapper">
            <Splide
                :options="{
                    arrows: false,
                    gap: '2rem',
                    perPage: 3,
                    padding: { right: '10%' },
                    breakpoints: {
                        600: {
                            gap: '1.25rem',
                            perPage: 1,
                        },
                        1100: {
                            gap: '1.5rem',
                            perPage: 2,
                        },
                    },
                }"
                class="ExpanderCardCarousel__splide"
            >
                <SplideSlide
                    v-for="item in transformedItems"
                    :key="item.id"
                    class="ExpanderCardCarousel__splide-slide"
                >
                    <ExpanderCard :item="item" />
                </SplideSlide>
            </Splide>
        </div>
    </section>
</template>

<script setup lang="ts">
import { type Content } from '@prismicio/client'
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import '@splidejs/vue-splide/css/core'
import { uid } from 'uid'

// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
const props = defineProps(
    getSliceComponentProps<Content.ExpanderCardCarouselSlice>([
        'slice',
        'index',
        'slices',
        'context',
    ]),
)

const transformedItems = computed(() => {
    return props.slice.items.map((item) => {
        return {
            id: uid(),
            title: item.card_title,
            body: item.card_description,
            icon: item.icon ? item.icon.replace(' ', '-').toLowerCase() : '',
        }
    })
})
</script>

<style lang="scss">
.ExpanderCardCarousel {
    @include grid;

    grid-column: 1 / span 24;
    overflow: hidden;
    margin-top: 5.5rem;

    @include medium-up {
        margin-top: 11.25rem;
    }
}

.ExpanderCardCarousel__head {
    grid-column: 2 / span 22;
    margin-bottom: 2.5rem;

    @include medium-up {
        margin-bottom: 3.75rem;
    }
}

.ExpanderCardCarousel__head-title {
    @include heading-2;

    margin-bottom: 1rem;

    @include medium-up {
        margin-bottom: 2rem;
    }
}

.ExpanderCardCarousel__head-desc {
    @include small-only {
        @include body-md;
    }

    @include medium-up {
        @include body-lg;
    }
    max-width: 43.625rem;
}

.ExpanderCardCarousel__carousel-wrapper {
    grid-column: 2 / span 22;
    overflow: visible;
}

.ExpanderCardCarousel__splide {
    .splide__track {
        overflow: visible;
    }
}
</style>
