<template>
    <div
        class="ExpanderCard"
        :class="{ 'ExpanderCard--active': pullUpAmount !== '0px' }"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
        @click="handleClick"
        role="button"
        :style="`--pullup-amount: -${pullUpAmount}`"
    >
        <ClientOnly v-if="isAnimatedIcon">
            <LottieIcon
                class="ExpanderCard__icon"
                :icon="item.icon"
                :play="playing"
                :colour="pullUpAmount !== '0px' ? 'black' : ''"
            />
        </ClientOnly>
        <div
            v-else-if="item.icon"
            class="ExpanderCard__icon ExpanderCard__icon--static"
        >
            <FontIcon
                :icon="item.icon"
                size="stretch"
                :color="pullUpAmount !== '0px' ? 'black' : 'white'"
            />
        </div>
        <div class="ExpanderCard__content">
            <div class="ExpanderCard__title-wrapper">
                <h3 class="ExpanderCard__title">{{ item.title }}</h3>
                <div class="ExpanderCard__title-icon">
                    <FontIcon
                        icon="plus"
                        :color="pullUpAmount !== '0px' ? 'black' : 'white'"
                        size="stretch"
                    />
                </div>
            </div>
            <div class="ExpanderCard__body" ref="bodyContent">
                {{ item.body }}
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
interface Item {
    id: string
    title: string
    body: string
    icon?: string
}

interface ExpanderCard {
    item: Item
}

const animatedIcons = [
    'airplane',
    'balloons',
    'cocktail',
    'globe',
    'health',
    'hybrid-working',
    'location',
    'people-centric',
    'professional-development',
    'seaside-resort',
    'suitcase',
]

const props = defineProps<ExpanderCard>()

const cursorStore = useCursorStore()

const playing = ref(false)
const pullUpAmount = ref('0px')
const bodyContent = ref()

const isAnimatedIcon = computed(() => {
    if (props.item.icon) {
        return animatedIcons.includes(props.item.icon)
    }

    return false
})

function handleMouseEnter() {
    playing.value = true
    cursorStore.setCursorType('link-hover')
}

function handleMouseLeave() {
    cursorStore.setCursorType('default')
    playing.value = false
}

function handleClick() {
    if (pullUpAmount.value === '0px') {
        handleMouseEnter()
        pullUpAmount.value = getComputedStyle(bodyContent.value).height
    } else {
        playing.value = false
        pullUpAmount.value = '0px'
    }
}
</script>
<style lang="scss">
.ExpanderCard {
    border: 0.5px solid var(--black-50);
    background-color: var(--black-20);
    padding: clamp(0.8rem, 2vw, 1.875rem);
    aspect-ratio: 1/1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
}

.ExpanderCard--active {
    background-color: var(--page-theme-base-colour, var(--lime-green));
    color: var(--black);

    .ExpanderCard__body {
        opacity: 1;
    }

    .ExpanderCard__title-icon {
        transform: rotate(45deg);
    }
}

.ExpanderCard__content {
    margin-top: auto;
    margin-bottom: 0;
}

.ExpanderCard__title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.ExpanderCard__title {
    @include heading-3;

    width: clamp(194px, 70%, 300px);
    font-size: clamp(1.125rem, 1.75vw, 2.5rem);
    transform: translateY(var(--pullup-amount));
    word-wrap: normal;
}

.ExpanderCard__body {
    @include body-md;

    font-size: clamp(0.7rem, 4vw, 0.9rem);
    position: absolute;
    top: calc(100% - 1.125rem);
    right: clamp(4.875rem, calc(10% + 3rem), 6rem);
    left: clamp(0.8rem, 2vw, 1.875rem);
    transform: translateY(var(--pullup-amount));
    opacity: 1;
    pointer-events: none;
    padding-top: 0.75rem;
    opacity: 0;

    @include medium-up {
        font-size: clamp(0.7rem, 2vw, 0.9rem);
        top: calc(100% - 1.875rem);
    }
}

.ExpanderCard__icon {
    width: clamp(5rem, 5vw, 10rem);
}

.ExpanderCard__icon--static {
    width: clamp(1rem, 12%, 5rem);
    margin-top: clamp(1rem, 5%, 2rem);
    margin-left: 1rem;
}

.ExpanderCard,
.ExpanderCard__title,
.ExpanderCard__body,
.ExpanderCard__title-icon {
    transition-property: transform, opacity, background-color;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
}

.ExpanderCard__title-icon {
    transform: rotate(0);
    width: clamp(1.5rem, 10%, 3rem);
}
</style>
